import React, { lazy } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

// Layout

const ProtectedLayout = lazy(() => import('./ProtectedLayout.js'));

// Errors

const ForbiddenPage = lazy(() => import('./Pages/ForbiddenPage/ForbiddenPage.js'));

const Error404Page = lazy(() => import('./Pages/Error404Page/Error404Page.js'));

// Страницы пользователя

const userRoutes = {
	path: 'user',
	children: [
		{
			index: true,
			Component: lazy(() => import('./Pages/AccountPage/AccountPage.js'))
		},
		{
			path: 'management',
			children: [
				{
					path: 'users',
					children: [
						{
							index: true,
							Component: lazy(() => import('./Pages/Management/UsersPage/UsersPage.js'))
						},
						{
							path: 'item/:id',
							Component: lazy(() => import('./Pages/Management/UserProfilePage/UserProfilePage.js'))
						},
					]
				}
			]
		},
		{
			path: 'registries',
			children: [
				{
					path: 'subjects',
					children: [
						{
							index: true,
							Component: lazy(() => import('./Pages/Registries/SubjectsJournal/JournalPage/JournalPage.js'))
						},
						{
							path: 'subject/:id',
							Component: lazy(() => import('./Pages/Registries/SubjectsJournal/DetailsPage/DetailsPage.js'))
						},
						{
							path: 'object/:id',
							Component: lazy(() => import('./Pages/Registries/SubjectsJournal/ObjectDetailsPage/ObjectDetailsPage.js'))
						},						
						{
							path: 'new',
							Component: lazy(() => import('./Pages/Registries/SubjectsJournal/NewRecordPage/NewRecordPage.js'))
						},
						{
							path: 'datasetbuilder',
							Component: lazy(() => import('./Pages/Registries/SubjectsJournal/BuilderPage/BuilderPage.js'))
						}
					]
				},
				{
					path: 'audit',
					children: [
						{
							index: true,
							Component: lazy(() => import('./Pages/Registries/AuditJournal/JournalPage/JournalPage.js'))
						},
						{
							path: 'item/:id',
							Component: lazy(() => import('./Pages/Registries/AuditJournal/DetailsPage/DetailsPage.js'))
						},
						{
							path: 'new',
							Component: lazy(() => import('./Pages/Registries/AuditJournal/NewRecordPage/NewRecordPage.js'))
						}
					]
				},
				{
					path: 'fire',
					children: [
						{
							index: true,
							Component: lazy(() => import('./Pages/Registries/FireJournal/JournalPage/JournalPage.js'))		
						},
						{
							path: 'item/:id',
							Component: lazy(() => import('./Pages/Registries/FireJournal/DetailsPage/DetailsPage.js'))		
						},
						{
							path: 'new',
							Component: lazy(() => import('./Pages/Registries/FireJournal/NewRecordPage/NewRecordPage.js'))		
						}
					]
				}
			]
		},

	]
};

// Справка и поддержка

const supportRoutes = {
	path: 'support'
}

// Router

const router = createBrowserRouter([
	{
		path: "/",
		element: <ProtectedLayout />,
		errorElement: <Error404Page />,
		children: [
			{
				index: true,
				Component: lazy(() => import('./Pages/HomePage/HomePage.js'))
			},
			{
				path: 'login',
				Component: lazy(() => import('./Pages/LoginPage/LoginPage.js'))
			},
			userRoutes,
			supportRoutes
		]
	}
]);

//

function AppRouter() {

  return <RouterProvider router={router} />;

}

export default AppRouter;